<template>
  <div class="askIndex">
    <div class="topbg"></div>

    <div class="topbox">
      <div class="labelbox flexbox base_w">
        <span v-for="(item,i) in LawKnowledgelist" :key="i"  @click.stop="change(i,item)" :class="[spanItem == i ? 'active' : '']">{{item.name}}</span>
      </div>

      <!-- 热点 -->
      <div class="hotbox flex_center">
        <div class="hotbox_left">
          <div>热点知识</div>
          <p 
            v-for="(item,i) in lawchildlist" :key="i" 
            :class="[hottype == i ? 'active' : '']" 
            @click.stop="changeChild(i,item)"
            >
            {{item.name}}
          </p>
        </div>
        <div class="hotbox_center">
          <p class="center_titl">免费咨询相关法律问题</p>
          <zixun :type="6" :title="title"> </zixun>
        </div>
        <div class="hotbox_right">
          <img src="@/assets/img/bgright1.png" alt="" class="bgright" />
        </div>
      </div>
    </div>

    <!-- 详情 -->
    <div class="askdetail base_w flex">
      <div class="common_index_leftwidth">
        <newslabel ref="news" :title="title" :list="lawchildlist1"></newslabel>
      </div>

      <div class="common_index_rightwidth">
        <!-- 热门律师 -->
        <div class="rmls">
          <div class="common_title_box">
            <span class="title">热门律师</span>
            <div class="righttext" @click.stop="tourl('/lvlist')">
              更多
              <img src="@/assets/img/right.png" alt="" class="iconimg" />
            </div>
          </div>
          <hotls :list="Lawlist"></hotls>
        </div>

        <!-- 广告位 -->
        <div class="zixunbo" @click.stop="tourl('/toask')">
          <div class="zaixian">在线咨询 >> </div>
          <img
            src="@/assets/img/icon_guang1.png"
            alt=""
            style=" width: 586px; height: 330px; border-radius: 13px; margin-top: 40px; "
          />
        </div>
       

        <!-- 法律资讯 -->
        <div class="flzx">
          <div class="common_title_box">
            <span class="title">法律资讯</span>
          </div>
          <div class="flzxbox">
            <news :type="2" :list="innewlist"></news>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import zixun from '@/components/zixun'
import hotls from '@/components/hotls'
import news from '@/components/news'
import newslabel from '@/components/newslabel'

import apiUrl from '@/api/main'
export default {
  components: { zixun, hotls, news, newslabel },
  data() {
    return {
      LawKnowledgelist:[],
      spanItem:0,
      id:'', // 父级id
      lawchildlist:[], // 热点知识 二级
      hottype:'',
      title:'',
      Lawlist:[],
      innewlist:[],
      lawchildlist1:[]
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getlsLawKnowledgelist();
    this.lsLawyerlist();
    this.getnewlist();
    // 跳转回顶部
    document.documentElement.scrollTop = 0;

  },
  methods: {
    // 跳转
    tourl(url) {
      this.$router.push({
        path: url
      })
    },
    // 热门领域
    getlsLawKnowledgelist() {
      let data = {
        pId: 0,
        userId: localStorage.getItem('userId') || ''
      }
      apiUrl.lsLawKnowledgelist(data).then((res) => {
        this.LawKnowledgelist = res.rows;
        if(res.rows.length > 0) {

            // 首页跳转到 问法
            if(this.$route.query.title){
              res.rows.forEach((element,i) => {
                if(element.name == this.$route.query.title){
                  this.id = element.id;
                  this.title = element.name;
                  this.spanItem = i;
                }
              });
            } else {
              this.id = res.rows[0].id;
              this.title = res.rows[0].name;
            }
           
        }

        this.lawKnowledgechild(); // 热点知识
        this.lawKnowledgechild1(); // 热点知识详情
      })
    },
    change(i,item){
        this.spanItem = i;
        this.id = item.id;
        this.lawKnowledgechild();
        this.lawKnowledgechild1(); // 热点知识详情
        this.title = item.name;
        this.$refs.news.open(item);
    },
    // 热点知识 TODO.. 这个地方调用后台浏览量高的标签吧,目前还没
    lawKnowledgechild(){
      let data = {
        pId: this.id,
				// userId: localStorage.getItem('userId') || '',
        pageSize: 6,
        pageNum: 1,
        orderByColumn: 'browses',
        isAsc: 'desc'
      }
      apiUrl.lsLawKnowledgelist(data).then((res) => {
        this.lawchildlist = res.rows;
      })
    },
    // 热点知识详情
    lawKnowledgechild1(){
      let data = {
        pId: this.id
      }
      apiUrl.lsLawKnowledgelist(data).then((res) => {
        this.lawchildlist1 = res.rows;
      })
    },
    // 切换 二级 热点知识
    changeChild(i,item){
      this.hottype = i;
      this.$router.push({
        path: '/askdetails?pId=' + item.pId + '&parentName=' + item.parentName + '&id=' + item.id
      })
    },

    // 获取律师列表
    lsLawyerlist() {
      let data = {
        pageNum: 1,
        pageSize: 3,
        orderByColumn: 'haopinhlv',
        isAsc: 'desc'
      }
      apiUrl.lsLawyerindex(data).then((res) => {
        res.rows.forEach((element) => {
          if (element.goodsIds) {
            element.goodsIdsArr = element.goodsIds.split(',')
          }
        })
        this.Lawlist = res.rows
      })
    },
     // 最新文章
     getnewlist() {
      let data = {
        pageSize: 5,
        pageNum: 1,
        // orderByColumn: 'createTime',
        // isAsc: 'desc'
      }
      apiUrl.lsInformationlist(data).then((res) => {
        this.innewlist = res.rows
      })
    },
 
  }
}
</script>
<style lang='scss' scoped>
.askIndex {
  .topbg {
    background: url('../../assets/img/bg15.png') no-repeat;
    background-size: 100% 100%;
    height: 320px;
  }
  .topbox {
    background: #f8f8f8;
    padding-bottom: 24px;
    .labelbox {
      padding: 40px 48px;
      flex-wrap: wrap;
      background-color: #fff;
      border-radius: 12px;
      transform: translateY(-50px);
      span {
        border-radius: 18px;
        border: 1px solid #cccccc;
        padding: 9px 32px;
        margin-right: 24px;
        margin-bottom: 20px;
        color: #333;
        font-size: 16px;
        cursor: pointer;
      }
      span:hover {
        color: #305bfe;
        border: 1px solid #305bfe;
      }
      span.active {
        background-color: #305bfe;
        color: #fff;
      }
    }

    // 热门领域
    .hotbox {
      // transform: translateY(-30px);
      margin-top: -23px;
      .hotbox_left {
        width: 240px;
        height: 424px;
        background: #ffffff;
        border-radius: 8px;
        padding: 20px 0 25px;
        overflow: auto;
        &::-webkit-scrollbar{
          display: none;
        }

        div {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 23px;
          border-left: 7px solid #305bfe;
          // text-align: center;
          margin-bottom: 10px;
          padding-left: 73px;
        }
        p {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          display: flex;
          align-items: center;
          // justify-content: center;
          padding-left: 80px;
          cursor: pointer;
          border-bottom: 1px solid #f5f5f5;
          height: 54px;
          line-height: 54px;
        }
        p:nth-last-child(1) {
          border-bottom: 1px solid transparent;
        }
        .active {
          color: #305bfe;
          background: #e9edfb;
        }
      }
      .hotbox_center {
        width: 852px;
        height: 424px;
        margin: 0 16px;
        padding: 33px;
        background: #dbe6ff;
        border-radius: 8px;
        .center_titl {
          font-size: 23px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 20px;
          letter-spacing: 2px;
        }
      }
      .hotbox_right {
        width: 316px;
        height: 424px;
        cursor: pointer;
        position: relative;

        .bgright {
          width: 316px;
          height: 424px;
        }
        > div {
          position: absolute;
          top: 135px;
          left: 0;
          right: 0;
          width: 137px;
          height: 40px;
          line-height: 40px;
          margin: 0 auto;
        }
      }
    }
  }

  .askdetail {
    margin-top: 40px;
    padding-bottom: 67px;
    .common_index_leftwidth {
    }

    // 热门律师
    .rmls {
      // margin-top: 33px;
      .common_title_box {
        margin-bottom: 13px;
      }
    }
    // 法律资讯
    .flzx {
      margin-top: 33px;
      .flzxbox {
        width: 586px;
        background: #f8f8f8;
        border-radius: 12px;
        padding: 32px;
        margin-top: 16px;
      }
    }
  }
}
</style>