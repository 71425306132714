<template>
  <el-dialog
    :title="title"
    :visible.sync="centerDialogVisible"
    width="333px"
    center>
    <div class="mainbox">
     
      <input type="text" placeholder="请输入您的手机号" v-model="phoneNumber">
      <div class="line"></div>
      <input type="text" placeholder="请输入验证码"  v-model="codeVal">
      <span @click="getCodeVal" class="getCodeVal">{{codeName}}</span>
      <div class="line"></div>

      <div class="agreebox flexbox">
        <img src="@/assets/img/choose.png" v-if="!checked"
        @click="checkedFun">
        <img src="@/assets/img/choosed.png" v-else @click="checkedFun">
        我已阅读并同意 
        <span class="spantext" @click.stop="tourl('/yonghu')"> 《用户协议》</span>和 
        <span class="spantext" @click.stop="tourl('/yinsi')">《隐私协议》 </span>
      </div>

    </div>
    <div slot="footer" class="dialog-footer">
      <div @click="onsubmit" class="btnbox1" >{{suretext}}</div>
    </div>
  </el-dialog>
</template>

<script>
import apiUrl from '@/api/login'

export default {
  name: 'login',
  components: {},
  // props: {
  //   type: {
  //     type: String,
  //     default: '1'
  //   }
  // },
  data() {
    return {
      centerDialogVisible:false,
      title:'登录', // 1 登录 2 注册
      suretext:'登录' ,// 1 登录 2 立即注册
      checked:false,
      phoneNumber:'', // 手机号
      codeVal:'', // 验证码
      codeName:'获取验证码',
      disabled: false,
      onlineCode: '',
    }
  },
  methods:{
    /***
     * 打开弹窗 1 登录 2 注册
     * 
     * */ 
    open(num){
      this.centerDialogVisible = true;
      this.cleardata();
      if(num == 1){
        this.title = '登录';
        this.suretext = '登录'
      }else if(num == 2){
        this.title = '注册';
        this.suretext = '立即注册'
      }
    },
    // 关闭弹窗
    close(){
      this.centerDialogVisible = false;
    },
    // 清空数据
    cleardata(){
      this.phoneNumber = '';
      this.codeVal = '';
      this.codeName = '获取验证码';
      this.disabled = false;
      this.onlineCode = '';
      this.checked = false;
      clearInterval(this.timer);
    },
    // 切换
    checkedFun(){
      this.checked = !this.checked;
    },
    // 获取验证码
    getCodeVal() {
      if (!this.phoneNumber) {
        this.$message({
          message: '请输入手机号',
          type: 'error'
        });
        return false;
      }
      if(!/^1[3456789]{1}\d{9}$/gi.test(this.phoneNumber)){
          this.$message({
            message: '请检查联系方式',
            type: 'error'
          });
					return false;
				}
      
        let data = {
          phone: this.phoneNumber
        }
        apiUrl.sendmessage(data)
        .then(res => {
          if (res.code != 0) {
            this.codeName = '获取验证码';
            this.disabled = false;
            clearInterval(this.timer)
          } else {
            this.setTimer();
            console.log(res.msg)
            this.onlineCode = res.msg;
          }
        })
		},
    setTimer() {
				let num = 60;
				this.disabled = true;
				this.timer = setInterval(() => {
					if (num > 1) {
						num--;
						this.codeName = num + 's';
					} else {
						this.codeName = '获取验证码';
						this.disabled = false;
						clearInterval(this.timer)
					}
				}, 1000)
			},
    // 提交
    onsubmit(){
      let that = this;

      if (!this.phoneNumber) {
        this.$message({
          message: '请输入手机号',
          type: 'error'
        });
        return false;
      }

      if(!/^1[3456789]{1}\d{9}$/gi.test(this.phoneNumber)){
        this.$message({
          message: '请检查联系方式',
          type: 'error'
        });
        return false;
      }

      if (!this.onlineCode || this.onlineCode != this.codeVal) {
        this.$message({
          message: '验证码错误',
          type: 'error'
        });
        return
      }
      if (!this.checked) {
        this.$message({
          message: '请阅读并同意协议',
          type: 'error'
        });
        return
      }

      let data = {
        phone: this.phoneNumber
      }
      apiUrl.login(data)
      .then(res => {
        if (res.code == 0) {
          that.initTIM(res.data.phone, res.data.userToken, res.data.photo,
							res.data.nikname, res.data.id);

          this.centerDialogVisible = false;
          localStorage.setItem('userId', res.data.id);
          localStorage.setItem("userinfo", JSON.stringify(res.data));
          this.cleardata();
          this.close();
          location.reload();
        }
      })
    },
    tourl(url) {
      let routeUrl = this.$router.resolve({
        path: url
      });
      window.open(routeUrl.href, '_blank');
      // this.cleardata();
      // this.close();
    },
    	//初始化聊天 tim
			initTIM(userID, userSig, avatarUrl, nickName, id) {
				console.log(userID, userSig, avatarUrl, nickName, id)
				let that = this;
				// 开始登录
        localStorage.setItem("userid", id);
        localStorage.setItem("userSig", userSig);
        localStorage.setItem("userimID", userID);
        localStorage.setItem("avatarUrl", avatarUrl);
        localStorage.setItem("nickName", nickName);

				that.$tim.login({
					userID: 'u' + userID,
					userSig: userSig
				}).then(function(imResponse) {
					console.log(imResponse.data); // 登录成功
					that.$tim.on(that.TIM.EVENT.SDK_READY, function(event) {
						// 修改个人标配资料
						let promise = that.$tim.updateMyProfile({
							nick: nickName,
							avatar: avatarUrl,
							allowType: that.TIM.TYPES.ALLOW_TYPE_ALLOW_ANY
						});
						promise.then(function(user) {
							console.log('更新资料成功'); // 更新资料成功
							console.log(user.data); // 更新资料成功
						}).catch(function(imError) {
							console.warn('updateMyProfile error:', imError); // 更新资料失败的相关信息
						});
					});
				}).catch(function(imError) {
          this.$message.error(imError);
					console.warn('login error:', imError); // 登录失败的相关信息
				});
			}
  }
}
</script>

<style lang='scss' scoped>

  .mainbox{
    padding-bottom: 13px;
    .line{
      width: 267px;
      border: 1px solid #D8D8D8;
      margin-bottom: 17px;
    }
    input{
      width: 173px;
      height: 40px;
    }
    span.getCodeVal{
      margin-left: 30px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #305BFE;
      cursor: pointer;
    }

    .agreebox{
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      img{
        width:15px;
        height: 15px;
        margin-right:10px;
      }
      span.spantext{
        color: #204696;
        cursor: pointer;
      }

    }
   
  }

  .btnbox1{
      width: 293px;
      height: 45px;
      line-height:45px;
      text-align: center;
      background: linear-gradient(270deg, #4298FF 0%, #305BFE 100%);
      border-radius: 28px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
    }
</style>